<template>
  <section class="multistepdone">
    <div class="container">
      <div class="content">
        <div class="check">
          <picture>
            <img src="../../assets/images/ico-check.png">
          </picture>
        </div>
        <h1>Hotovo!</h1>
        <p>Nemovitost odeslána, nyní počkejte na schválení.</p>
        <div class="picture">
          <picture>
            <img src="../../assets/images/done-picture.png">
          </picture>
        </div>
        <router-link to="/" class="bigger-font uk-button uk-button-primary uk-align-center">Domů</router-link>
        <router-link v-if="id" :to="{name: 'viewonly', params: {id: id}}" class="bigger-font uk-button uk-button-primary uk-align-center">Přehled nemovitostí</router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Done",
  data(){
    return{
      id: null
    }
  },
  created() {
    this.id = this.$route.params.id;
  },
}
</script>

<style lang="less" scoped>
  section.multistepdone{
    .container{
      position: relative;
      max-width: 42.8rem;
      height: 92vh;
      overflow: auto;
      margin: auto;
      background: rgba(143,187,105,1);
      background: -moz-linear-gradient(top, rgba(143,187,105,1) 0%, rgba(47,172,102,1) 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(143,187,105,1)), color-stop(100%, rgba(47,172,102,1)));
      background: -webkit-linear-gradient(top, rgba(143,187,105,1) 0%, rgba(47,172,102,1) 100%);
      background: -o-linear-gradient(top, rgba(143,187,105,1) 0%, rgba(47,172,102,1) 100%);
      background: -ms-linear-gradient(top, rgba(143,187,105,1) 0%, rgba(47,172,102,1) 100%);
      background: linear-gradient(to bottom, rgba(143,187,105,1) 0%, rgba(47,172,102,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8fbb69', endColorstr='#2fac66', GradientType=0 );
      box-sizing: border-box;
      padding-bottom: 8rem;
      .steps-count{
        background: #fff;
        padding: 1.8rem 0;
        .steps{
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
                  justify-content: space-between;
          -webkit-box-align: center;
          -ms-flex-align: center;
                  align-items: center;
          width: 100%;
          max-width: 27rem;
          margin: auto;
          a{
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            width: 4.3rem;
            height: 4.3rem;
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 2.1rem;
            line-height: 2.1rem;
            color: #2FAC66;
            background: #fff;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.16);
            -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.16);
            box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.16);
            &.active{
              background: #2FAC66;
              color: #fff;
              &:hover{
                background: #fff;
                color: #2FAC66;
              }
            }
            &:hover{
              text-decoration: none;
              background: #2FAC66;
              color: #fff;
            }
            &:before{
              content: "";
              position: absolute;
              top: 0;
              right: -2rem;
              bottom: 0;
              margin: auto;
              width: 0.9rem;
              height: 1.7rem;
              background: url('../../assets/images/ico-arrow-right.png');
              background-size: cover;
              background-repeat: no-repeat;
              z-index: 1;
            }
            &:last-of-type{
              &:before{
                content: none;
              }
            }
          }
        }
      }
      .content{
        .check{
          position: relative;
          display: block;
          overflow: hidden;
          width:10.8rem;
          height: 10.8rem;
          margin: auto;
          margin-top: 15%;
          margin-bottom: 3rem;
          picture{
            img{
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
        h1{
          font-family: 'Poppins';
          font-weight: 700;
          font-size: 5rem;
          line-height: 7.6rem;
          color: #fff;
          text-align: center;
          margin-top: 0;
          margin-bottom: 2rem;
        }
        p{
          position: relative;
          font-family: 'Poppins';
          font-weight: 400;
          font-size: 1.5rem;
          line-height: 2.3rem;
          color: #fff;
          text-align: center;
          width: 100%;
          max-width: 33rem;
          margin: auto;
          margin-top: 0;
          margin-bottom: 4rem;
        }
        .picture{
          position: relative;
          display: block;
          overflow: hidden;
          width:27.6rem;
          height: 12rem;
          margin: auto;
          picture{
            img{
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
  .bigger-font{
    font-size:1.25em;
  }
</style>